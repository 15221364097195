<!--
 * @Author: wxy
 * @Date: 2022-09-26 10:55:14
 * @LastEditTime: 2022-09-26 10:55:30
 * @Description: 全部工单-详情
-->
<template>
  <div>全部工单详情</div>
</template>

<script>
export default {};
</script>

<style>

</style>